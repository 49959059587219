interface CookieOptions {
  path?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
  expires?: Date;
}

export const setCookie = (name: string, value: string, options: CookieOptions = {}) => {
  const { path = '/', secure = true, sameSite = 'strict', expires } = options;

  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (path) cookieString += `; path=${path}`;

  if (secure) cookieString += '; secure';

  if (sameSite) cookieString += `; samesite=${sameSite}`;

  if (expires) cookieString += `; expires=${expires.toUTCString()}`;

  document.cookie = cookieString;
};

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  const cookieName = encodeURIComponent(name);

  for (const cookie of cookies) {
    const [key, value] = cookie.trim().split('=');
    if (key === cookieName) {
      return decodeURIComponent(value);
    }
  }

  return null;
};

export const removeCookie = (name: string, path = '/') => {
  const expires = new Date(0);
  setCookie(name, '', { path, expires });
};
