/* eslint-disable no-throw-literal */
import { requestWithAuth, requestWithoutAuth } from 'utils/common/axios-utils';

import {
  AccountFieldValidationPayload,
  ChangePasswordPayload,
  LogInApiPayload,
  LogInApiResponse,
  LogOutApiPayload,
  SendValidationCodePayload,
  SignupApiPayload,
  SignupApiResponse,
} from 'types/account/remote';

export const signupApi = async (signupInfo: SignupApiPayload) => {
  const { username, email, password, nickname } = signupInfo;

  return await requestWithoutAuth<SignupApiResponse>({
    method: 'post',
    url: `/accounts/`,
    data: { username, email, password, nickname, account_type: 2 },
  });
};

export const logInApi = async (payload: LogInApiPayload) => {
  return await requestWithoutAuth<LogInApiResponse>({
    method: 'post',
    url: `/accounts/login/`,
    data: payload,
  });
};

export const logOutApi = async (payload: LogOutApiPayload) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/logout/`,
    data: payload,
  });
};

export const issueTokenRequestApi = async (refreshToken: string) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/token/refresh/`,
    data: { refresh: refreshToken },
  });
};

export const accountFieldsValidation = async ({
  access_token,
  field,
  data,
  isSignUp = true,
}: AccountFieldValidationPayload) => {
  return isSignUp
    ? await requestWithoutAuth({
        method: 'post',
        url: `/accounts/validation/${field}/`,
        data,
        'Content-Type': 'multipart/form-data',
      })
    : await requestWithAuth(
        {
          method: 'post',
          url: `/accounts/validation/${field}/`,
          data,
          'Content-Type': 'multipart/form-data',
        },
        access_token
      );
};

export const sendValidationCode = async ({ field, data }: SendValidationCodePayload) => {
  return await requestWithoutAuth({
    method: 'patch',
    url: `/accounts/validation/${field}/`,
    data,
    'Content-Type': 'multipart/form-data',
  });
};

export const sendCodeToResetPassword = async (email: string) => {
  return await requestWithoutAuth({
    method: 'get',
    url: `/accounts/passwordreset/?email=${email}`,
  });
};

export const verifyCodeToResetPassword = async (formData: FormData) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/passwordreset/`,
    data: formData,
    'Content-Type': 'multipart/form-data',
  });
};

export const resetPassword = async (email: string) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/password/reset/`,
    data: { email },
  });
};

export const changePassword = async (payload: ChangePasswordPayload, access_token: string) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/password/change/`,
      data: payload,
    },
    access_token
  );
};
