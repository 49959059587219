import { ChangeEvent } from 'react';

import { Pagination } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import SectionLine from 'components/common/SectionLine';
import Tooltip from 'components/common/Tooltip';
import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import BulkMessageTableRow from 'components/pages/chat/bulkMessage/list/BulkMessageTableRow';

import { color } from 'styles/assets';

import { PaginationType } from 'types/common';

interface Props {
  page: number;
  page_size: number;
  paginationHandler: (event: ChangeEvent<unknown>, page: number) => void;
  listData: PaginationType<any>;
}

const BulkMessageTable = ({ page, page_size, paginationHandler, listData }: Props) => {
  const { count, results = [] } = listData;

  return (
    <Container>
      <TableWrapper>
        <Table gap={60} isEmpty={results.length === 0}>
          <Table.Header borderBottom={{ width: 1, style: 'solid', color: 'greye4' }} paddingBottom={24} paddingTop={24}>
            <Table.Head flex={1} minWidth="70px">
              <StyledText fontSize="base" fontWeight="medium" text={'ID'} />
            </Table.Head>
            <Table.Head flex={2} minWidth="130px">
              <StyledText fontSize="base" fontWeight="medium" text={'Date'} />
            </Table.Head>
            <Table.Head flex={2} minWidth="120px">
              <StyledText fontSize="base" fontWeight="medium" text={'Campaign'} />
            </Table.Head>
            <Table.Head flex={2} minWidth="135px">
              <StyledText fontSize="base" fontWeight="medium" text={'Campaign ID'} />
            </Table.Head>
            <Table.Head flex={2} minWidth="100px">
              <StyledText fontSize="base" fontWeight="medium" text={'Branch'} />
            </Table.Head>
            <Table.Head flex={2} minWidth="100px">
              <StyledText fontSize="base" fontWeight="medium" text={'Sender'} />
            </Table.Head>
            <Table.Head flex={4} minWidth="440px">
              <StyledText fontSize="base" fontWeight="medium" text={'Campaign Title'} />
            </Table.Head>
            <Table.Head flex={3} minWidth="230px">
              <StyledText fontSize="base" fontWeight="medium" text={'Send To'} />
            </Table.Head>
            <Table.Head flex={1} minWidth="90px">
              <StyledText fontSize="base" fontWeight="medium" text={'Total'} />
            </Table.Head>
            <Table.Head flex={1} minWidth="90px">
              <StyledText fontSize="base" fontWeight="medium" text={'Success'} />
            </Table.Head>
            <Table.Head flex={1} minWidth="90px">
              <FailHeader>
                <StyledText fontSize="base" fontWeight="medium" text={'Fail'} />
                <FailNoticeWrapper>
                  <Tooltip
                    width="280px"
                    tooltipDirection="bottom"
                    hoverElement={<Icon type="info_circle" fill="violet39" />}
                    padding="11px"
                  >
                    <StyledText
                      text={t('chat.bulkMessage-list-title-fail-notice')}
                      fontSize="sm"
                      fontWeight="regular"
                      fontColor="white"
                      textAlign="center"
                    />
                  </Tooltip>
                </FailNoticeWrapper>
              </FailHeader>
            </Table.Head>
            <Table.Head flex={2} minWidth="180px">
              <StyledText fontSize="base" fontWeight="medium" text={'Status'} />
            </Table.Head>
          </Table.Header>

          <Table.Body borderBottom={{ width: 1, style: 'solid', color: 'greye4' }}>
            {results.map((data: any, index: number) => {
              return <BulkMessageTableRow key={index} rowData={data} />;
            })}
          </Table.Body>
        </Table>
      </TableWrapper>

      <SectionLine height="1px" backgroundColor={color.greye4} marginBottom="0px" marginTop="0px" />

      <Pagination
        count={Math.ceil(count / page_size) || 1}
        page={page}
        onChange={paginationHandler}
        boundaryCount={3}
        style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px', marginBottom: '20px' }}
      />
    </Container>
  );
};

export default BulkMessageTable;

const Container = styled.div`
  background-color: ${color.white};
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

const FailHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FailNoticeWrapper = styled.div`
  position: relative;
`;
