import { getUserRole } from '@NURIHAUS-Dev/core-business';

import { UserDetails } from 'types/account/internal';

export type UserType = 'super' | 'brand' | 'influencer' | 'rejected' | 'partner' | 'verified' | 'normal';

export const loginPermission = (user: UserDetails) => {
  const { is_staff, account_type, email_valid } = user;
  // staff and partner can access
  if (is_staff || account_type === 5) return 'accessAvailable';
  // influencer, rejected account block
  else if (account_type !== 2) throw { response: { data: "You don't have permission." } };
  // normal user and business user need to authenticate email
  else if (!email_valid) return 'AuthenticateEmail';
  // business user who authenticated email can access
  else return 'accessAvailable';
};

export const upperTabMenu = (
  user: UserDetails
): { primary: string; pathname: string; query?: { [key: string]: string }; check: (p: string) => boolean }[] => {
  const userType = getUserRole(user);

  const dashboard = {
    primary: 'Dashboard',
    pathname: '',
    check: (pathname: string) => pathname.split('/')?.[1] === '',
  };
  const chat = {
    primary: 'Chat',
    pathname: 'chat',
    query: { type: '0', tab: 'campaign', search: '' },
    check: (pathname: string) => pathname.split('/')?.[1] === 'chat',
  };
  const banner = {
    primary: 'Banner',
    pathname: 'banner',
    check: (pathname: string) => pathname.split('/')?.[1] === 'banner',
  };
  const benefit = {
    primary: 'Benefit',
    pathname: 'list/benefit',
    check: (pathname: string) => pathname.split('/')?.[2] === 'benefit',
  };
  const quest = {
    primary: 'Quest',
    pathname: 'list/quest',
    check: (pathname: string) => pathname.split('/')?.[2] === 'quest',
  };
  const users = {
    primary: 'Users',
    pathname: 'users',
    check: (pathname: string) => pathname.split('/')?.[1] === 'users',
  };
  const client = {
    primary: 'Client',
    pathname: 'client',
    check: (pathname: string) => pathname.split('/')?.[1] === 'client',
  };
  const myJelly = {
    primary: 'My Jelly',
    pathname: 'point/my-jelly',
    check: (pathname: string) => pathname.split('/')?.[1] === 'point' && pathname.split('/')?.[2] === 'my-jelly',
  };
  const myCredit = {
    primary: 'My Credit',
    pathname: 'point/my-credit',
    check: (pathname: string) => pathname.split('/')?.[1] === 'point' && pathname.split('/')?.[2] === 'my-credit',
  };
  const payout = {
    primary: 'Payout',
    pathname: 'payout',
    check: (pathname: string) => pathname.split('/')?.[1] === 'payout',
  };
  const analytics = {
    primary: 'Analytics',
    pathname: 'analytics',
    check: (pathname: string) => pathname.split('/')?.[1] === 'analytics',
  };
  const notifications = {
    primary: 'Notifications',
    pathname: 'notifications',
    check: (pathname: string) => pathname.split('/')?.[1] === 'notifications',
  };

  switch (userType) {
    case 'partner':
      return [dashboard, chat, banner, benefit, quest, users, notifications];
    case 'super':
      return [
        dashboard,
        chat,
        banner,
        benefit,
        quest,
        users,
        client,
        myJelly,
        myCredit,
        payout,
        analytics,
        notifications,
      ];
    case 'brand':
      return [dashboard, chat, benefit, quest, myJelly, myCredit];
    default:
      return [];
  }
};
