import { useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { accountFieldsValidation, changePassword, logInApi, logOutApi, signupApi } from 'api/account/Auth';
import { editProfile } from 'api/account/User';
import { connectSendbird, updateSendbirdProfile } from 'api/chat';

import { loginPermission } from 'utils/account/accessLevel';
import { removeCookie, setCookie } from 'utils/common/cookie';

import { useChat } from 'hooks/chat/queries';

import { AppContext } from 'features/redux/context';
import { selectAccount } from 'features/redux/selectors/accounts';
import { Login, SignOut } from 'features/redux/slices/accountSlice';

import { UserDetails } from 'types/account/internal';
import {
  AccountFieldValidationPayload,
  LogInApiPayload,
  LogInApiResponse,
  LogOutApiPayload,
  SignupApiPayload,
  SignupApiResponse,
} from 'types/account/remote';

interface PatchProfilePayload {
  profile?: null | File | string;
  nickname?: string;
}

interface PatchPasswordPayload {
  password: string;
  passwordCheck: string;
}

export const usePatchProfile = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: UserDetails) => void;
  onError?: (err: AxiosError) => void;
}) => {
  const account = useSelector(selectAccount);
  const { access_token } = account.userInfo;

  return useMutation(
    (props: PatchProfilePayload) => {
      const { profile, nickname } = props;
      const formData = new FormData();
      if (profile && typeof profile !== 'string') formData.append('profile', profile);

      if (nickname) formData.append('nickname', nickname);

      return editProfile(formData, access_token);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const usePatchPassword = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: UserDetails) => void;
  onError?: (err: AxiosError) => void;
}) => {
  const account = useSelector(selectAccount);
  const { access_token } = account.userInfo;

  return useMutation(
    (props: PatchPasswordPayload) => {
      const { password, passwordCheck } = props;
      const payload = { new_password1: password, new_password2: passwordCheck };

      return changePassword(payload, access_token);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const usePostLogin = () => {
  const dispatch = useDispatch();
  const { sendbird } = useContext(AppContext);
  const { mutate: postAccountFieldValidation } = usePostAccountFieldValidation();
  const { useCreateUser } = useChat();
  const { mutate: talkplusLogin } = useCreateUser();

  return useMutation<LogInApiResponse, AxiosError, LogInApiPayload, unknown>({
    mutationFn: (payload: LogInApiPayload) => logInApi(payload),
    onSuccess: (data) => {
      setCookie('accessToken', data.access_token);
      setCookie('refreshToken', data.refresh_token);

      const { username, profile, nickname } = data.user;
      const loginHandler = async (loginData: LogInApiResponse) => {
        await connectSendbird({ sendbird, username });
        dispatch(Login(loginData));
        await updateSendbirdProfile({ sendbird, nickname, profile });
        talkplusLogin({
          userId: loginData.user.id.toString(),
          username: loginData.user.nickname,
          profileImageUrl: loginData.user.get_profile,
          data: {
            nuriUsername: loginData.user.username,
          },
        });
      };

      switch (loginPermission(data.user)) {
        case 'AuthenticateEmail':
          const userLoginData = new FormData();
          userLoginData.append('type', '1');
          userLoginData.append('value', data?.user?.email);

          postAccountFieldValidation({
            access_token: data.access_token,
            field: 'email',
            data: userLoginData,
            isSignUp: false,
          });
          break;
        case 'accessAvailable':
        default:
          loginHandler(data);
      }
    },
  });
};

export const usePostSignup = () => {
  const { mutate: postLogin } = usePostLogin();

  return useMutation<SignupApiResponse, AxiosError, SignupApiPayload, unknown>({
    mutationFn: (payload: SignupApiPayload) => signupApi(payload),
    onSuccess: (_, payload) => {
      postLogin({ username: payload.username, password: payload.password });
    },
  });
};

export const usePostAccountFieldValidation = () => {
  return useMutation({
    mutationFn: (payload: AccountFieldValidationPayload) => accountFieldsValidation(payload),
  });
};

export const usePatchProfileAfterLogin = () => {
  return useMutation({
    mutationFn: (payload: { formData: FormData; access_token: string }) =>
      editProfile(payload.formData, payload.access_token),
  });
};

export const usePostLogout = () => {
  const { sendbird } = useContext(AppContext);
  const { useLogout } = useChat();
  const { mutate: talkplusLogout } = useLogout();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: LogOutApiPayload) => logOutApi(payload),
    onSettled() {
      dispatch(SignOut());
      sendbird.disconnect();
    },
    onSuccess() {
      talkplusLogout();
      removeCookie('accessToken');
      removeCookie('refreshToken');
    },
  });
};
