/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Router from 'router/Router';
import { initializeI18next } from 'translate/i18next/i18next';

import { getCountryList, getCurrencyList } from 'api/account/Address';
import { getBannerDataBySlug } from 'api/main';

import { useChat } from 'hooks/chat/queries';

import { AppContext } from 'features/redux/context';
import { selectAccount } from 'features/redux/selectors/accounts';
import { selectOption } from 'features/redux/selectors/options';
import { SetLanguage } from 'features/redux/slices/optionSlice';

const getAdminGuideLink = (language: string, dispatch: any) => {
  return getBannerDataBySlug(`web-guide_${language}`, dispatch).catch(() => {
    getBannerDataBySlug(`web-guide_kr`, dispatch).catch(() => {
      console.log('banner error');
    });
  });
};

function App() {
  const { language } = useSelector(selectOption);
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();
  const { useCreateUser, useLogout } = useChat();
  const { mutate: login } = useCreateUser();
  const { mutate: logout } = useLogout();
  const { sendbird } = useContext(AppContext);

  useEffect(() => {
    if (!account.isLogin || !account.userInfo.access_token) return;

    getCountryList(dispatch, account.userInfo.access_token);
    getCurrencyList(account.userInfo.access_token, dispatch);
  }, [account.isLogin]);

  useEffect(() => {
    if (language) {
      initializeI18next(language);
      dispatch(SetLanguage(language));
    } else {
      initializeI18next('en');
      dispatch(SetLanguage('en'));
    }

    getAdminGuideLink(language, dispatch);
  }, [language]);

  useEffect(() => {
    if (!account.isLogin) {
      logout();
      sendbird.disconnect();

      return;
    }

    // 로그인 상태일 때만 실행
    login({
      userId: account.userInfo.user.id.toString(),
      username: account.userInfo.user.nickname,
      profileImageUrl: account.userInfo.user.get_profile,
      data: {
        nuriUsername: account.userInfo.user.username,
      },
    });

    return () => {
      // cleanup 함수
      logout();
      sendbird.disconnect();
    };
  }, [account.isLogin]);

  return <Router />;
}

export default App;
