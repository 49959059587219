import { Fragment } from 'react';

import { UserDetails, getUserRole } from '@NURIHAUS-Dev/core-business';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatDateWithDot } from 'utils/common/dateFormat';
import { firstLetterUpperCase } from 'utils/common/firstLetterUpperCase';

import { bulkMessageTargetOptions } from 'hooks/chat/bulkMessage/useBulkMessageModal';

import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import StatusLabel from 'components/pages/chat/bulkMessage/list/StatusLabel';

import { selectAddress } from 'features/redux/selectors/address';

import { Messaging, MessagingStatus, MessagingUserStatus } from 'types/bulkMessge';

const BulkMessageTableRow = ({ rowData }: { rowData: Messaging }) => {
  const navigate = useNavigate();
  const option = bulkMessageTargetOptions();

  const { id, schedule, content_type, object_id, status, content_object, user, total, success, fail, user_status } =
    rowData;
  const { title } = content_object;
  const sender = user.is_staff ? 'Staff' : firstLetterUpperCase(getUserRole(user as UserDetails));
  const { branch } = content_object;
  const target = MessagingUserStatus[user_status];
  const targetOption = option.find((o) => o.value === target);
  const isSent = status === MessagingStatus.SENT;

  const { country } = useSelector(selectAddress);
  const c = country.find((c) => c.id === branch);
  const cLabel = c ? c.emoji + ' ' + c.iso2 : '';

  const handleClick = () => {
    navigate(`/chat/bulk-message/${rowData.id}`);
  };

  return (
    <Fragment>
      <Table.Cell flex={1} minWidth="70px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="bold" text={id.toString()} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="130px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={schedule ? formatDateWithDot(schedule) : '-'} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="120px" handleClick={handleClick}>
        <StyledText
          fontSize="base"
          fontWeight="medium"
          text={content_type === 74 ? 'Quest' : content_type === 85 ? 'Benefit' : '-'}
        />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="135px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={object_id.toString()} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="100px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={cLabel} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="100px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={sender} />
      </Table.Cell>
      <Table.Cell flex={4} minWidth="440px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={title} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={3} minWidth="230px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={targetOption?.label} />
      </Table.Cell>
      <Table.Cell flex={1} minWidth="90px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={isSent ? total.toString() : '-'} />
      </Table.Cell>
      <Table.Cell flex={1} minWidth="90px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" fontColor="green" text={isSent ? success.toString() : '-'} />
      </Table.Cell>
      <Table.Cell flex={1} minWidth="90px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" fontColor="reddf" text={isSent ? fail.toString() : '-'} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="180px" handleClick={handleClick}>
        <StatusLabel status={status} />
      </Table.Cell>
    </Fragment>
  );
};

export default BulkMessageTableRow;
