import React, { createContext, useContext } from 'react';

import styled from 'styled-components';

import EmptyRow from 'components/pages/users/detail/campaigns/table/EmptyRow';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  children: React.ReactNode;
  spacing?: number;
  gap?: number;
  isEmpty?: boolean;
}

interface TableContextProps {
  gap: number;
  isEmpty: boolean;
}

interface CustomBorderBottomStyle {
  width: number;
  style: 'none' | 'hidden' | 'dotted' | 'dashed' | 'solid' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset';
  color: ColorTypes;
}

const TableContext = createContext<TableContextProps>({ gap: 40, isEmpty: false });

const Table = ({ children, spacing = 0, gap = 40, isEmpty = false }: Props) => {
  return (
    <TableContext.Provider value={{ gap, isEmpty }}>
      <TableContainer spacing={spacing}>{children}</TableContainer>
    </TableContext.Provider>
  );
};

const Header = ({
  children,
  paddingTop,
  paddingBottom,
  borderBottom,
}: {
  borderBottom?: CustomBorderBottomStyle;
  children: React.ReactNode[];
  paddingTop?: number;
  paddingBottom?: number;
}) => {
  const { gap } = useContext(TableContext);

  return (
    <TableHeadWrapper borderBottom={borderBottom}>
      <HeaderRow gap={gap} paddingTop={paddingTop || 20} paddingBottom={paddingBottom || 0}>
        {children}
      </HeaderRow>
    </TableHeadWrapper>
  );
};

const Head = ({ children, flex = 1, minWidth }: { children: React.ReactNode; flex?: number; minWidth?: string }) => {
  return (
    <TableHead flex={flex} minWidth={minWidth}>
      {children}
    </TableHead>
  );
};

const Body = ({ children, borderBottom }: { children: React.ReactNode[]; borderBottom: CustomBorderBottomStyle }) => {
  const { isEmpty, gap } = useContext(TableContext);

  return (
    <TableBodyContainer>
      {isEmpty ? (
        <EmptyRow />
      ) : (
        children.map((child, index) => {
          const isLast = children.length === index + 1;

          return (
            <TableBodyRowContainer gap={gap} key={index} borderBottom={isLast ? undefined : borderBottom}>
              {child}
            </TableBodyRowContainer>
          );
        })
      )}
    </TableBodyContainer>
  );
};

const Cell = ({
  children,
  handleClick,
  flex,
  minWidth,
}: {
  children: React.ReactNode;
  handleClick: () => void;
  flex?: number;
  minWidth?: string;
}) => {
  return (
    <TableCellContainer flex={flex} minWidth={minWidth} onClick={handleClick}>
      {children}
    </TableCellContainer>
  );
};

Table.Header = Header;
Table.Head = Head;
Table.Body = Body;
Table.Cell = Cell;

export default Table;

const TableContainer = styled.table<{ spacing: number }>`
  width: 100%;
  height: 100%;
  border-spacing: ${({ spacing }) => spacing}px;
  overflow: hidden;
`;

const TableHeadWrapper = styled.thead<{ borderBottom?: CustomBorderBottomStyle }>`
  width: 100%;
  height: 70px;
  display: flex;
  border-bottom-width: ${({ borderBottom }) => (borderBottom ? borderBottom.width : 0)}px;
  border-bottom-style: ${({ borderBottom }) => (borderBottom ? borderBottom.style : 'none')};
  border-bottom-color: ${({ borderBottom }) => (borderBottom ? color[borderBottom.color] : 'black')};
`;

const TableHead = styled.th<{ flex: number; minWidth?: string }>`
  :first-child {
    padding-left: 20px;
  }
  :last-child {
    padding-right: 20px;
  }
  flex: ${({ flex }) => flex || 1};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  text-align: end;
  display: flex;
  align-items: flex-end;
  padding: 0;
`;

const TableBodyContainer = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
`;

const TableBodyRowContainer = styled.tr<{ gap: number; borderBottom?: CustomBorderBottomStyle }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 76px;
  gap: ${({ gap }) => gap}px;
  border-bottom-width: ${({ borderBottom }) => (borderBottom ? borderBottom.width : 0)}px;
  border-bottom-style: ${({ borderBottom }) => (borderBottom ? borderBottom.style : 'none')};
  border-bottom-color: ${({ borderBottom }) => (borderBottom ? color[borderBottom.color] : 'black')};
  td {
    :first-child {
      padding-left: 20px;
    }
    :last-child {
      padding-right: 20px;
    }
    display: flex;
    align-items: center;
    height: 76px;
  }
`;

const TableCellContainer = styled.td<{ flex?: number; minWidth?: string }>`
  cursor: pointer;
  padding: 0;
  flex: ${({ flex }) => flex || 1};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
`;

const HeaderRow = styled.tr<{ gap: number; paddingTop: number; paddingBottom: number }>`
  display: flex;
  width: 100%;
  gap: ${({ gap }) => gap}px;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
`;
