import axios, { AxiosRequestConfig } from 'axios';

import { issueTokenRequestApi } from 'api/account/Auth';

import { getCookie, removeCookie, setCookie } from 'utils/common/cookie';

import { SignOut } from 'features/redux/slices/accountSlice';
import { store } from 'features/redux/store';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  const accessToken = getCookie('accessToken');

  if (accessToken) {
    config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };
  }

  // URL 파라미터 처리 로직 추가
  if (config.params) {
    Object.entries(config.params).forEach(([key, value]) => {
      config.url = config.url?.replace(`:${key}`, String(value));
    });
  }

  return config;
});

let isInstanceRefreshing = false;
let requestForInstanceRetryQueue: {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}[] = [];

instance.interceptors.response.use(
  (response) => {
    if (response.data?.access_token) {
      setCookie('accessToken', response.data.access_token);
    }

    if (response.data?.refresh_token) {
      setCookie('refreshToken', response.data.refresh_token);
    }

    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      if (!isInstanceRefreshing) {
        isInstanceRefreshing = true;

        try {
          const refreshToken = getCookie('refreshToken');
          const response = await issueTokenRequestApi(refreshToken ?? '');

          requestForInstanceRetryQueue.forEach(({ config, resolve, reject }) => {
            instance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          requestForInstanceRetryQueue.length = 0;

          setCookie('accessToken', response.access);
          setCookie('refreshToken', response.refresh);

          return instance(originalRequest);
        } catch (refreshError) {
          requestForInstanceRetryQueue = [];
          removeCookie('accessToken');
          removeCookie('refreshToken');

          store.dispatch(SignOut());

          return Promise.reject(refreshError);
        } finally {
          isInstanceRefreshing = false;
        }
      }

      if (error.response.data.code === 'token_not_valid') {
        removeCookie('accessToken');
        removeCookie('refreshToken');

        store.dispatch(SignOut());
      }

      return new Promise<void>((resolve, reject) => {
        requestForInstanceRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

// API 인스턴스에도 동일한 로직 적용
export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const accessToken = getCookie('accessToken');

  if (accessToken) {
    config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };
  }

  // URL 파라미터 처리 로직 추가
  if (config.params) {
    Object.entries(config.params).forEach(([key, value]) => {
      config.url = config.url?.replace(`:${key}`, String(value));
    });
  }

  return config;
});

let isApiRefreshing = false;
let requestForAPIRetryQueue: {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}[] = [];

api.interceptors.response.use(
  (response) => {
    if (response.data?.access_token) {
      setCookie('accessToken', response.data.access_token);
    }

    if (response.data?.refresh_token) {
      setCookie('refreshToken', response.data.refresh_token);
    }

    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      if (!isApiRefreshing) {
        isApiRefreshing = true;

        try {
          const refreshToken = getCookie('refreshToken');
          const response = await issueTokenRequestApi(refreshToken ?? '');

          requestForAPIRetryQueue.forEach(({ config, resolve, reject }) => {
            api
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          requestForAPIRetryQueue.length = 0;

          setCookie('accessToken', response.access);
          setCookie('refreshToken', response.refresh);

          return api(originalRequest);
        } catch (refreshError) {
          requestForAPIRetryQueue = [];
          removeCookie('accessToken');
          removeCookie('refreshToken');

          store.dispatch(SignOut());

          return Promise.reject(refreshError);
        } finally {
          isApiRefreshing = false;
        }
      }

      if (error.response.data.code === 'token_not_valid') {
        removeCookie('accessToken');
        removeCookie('refreshToken');

        store.dispatch(SignOut());
      }

      return new Promise<void>((resolve, reject) => {
        requestForAPIRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);
