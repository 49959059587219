/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { api, instance } from 'api/core';

export type HttpMethod = 'get' | 'post' | 'patch' | 'delete' | 'put';
interface ApiParams<RequestType = any, QueryType = any> {
  data?: RequestType;
  query?: QueryType;
  params?: Record<string, any>;
}

interface CreateApiFunctionOptions {
  method: HttpMethod;
  url: string;
}
interface RequestOptions {
  method?: 'get' | 'post' | 'patch' | 'delete' | 'put';
  url?: string;
  data?: any;
  'Content-Type'?: 'application/json' | 'multipart/form-data' | 'application/x-www-form-urlencoded' | 'text/csv';
}

/** @todo requestWithAuth 함수에 token 인자 제거, 해당 함수 사용하는 api함수 및 쿼리에 모두 access_token인자 제거 , redux에서 토큰 관리 제거*/
export const requestWithAuth = async <T = any>({ ...options }: RequestOptions, token: string) => {
  try {
    const response: AxiosResponse<T> = await instance(options);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  }
};

export const requestWithoutAuth = async <T = any>({ ...options }: RequestOptions) => {
  delete instance.defaults.headers.common.Authorization;

  try {
    const response: AxiosResponse<T> = await instance(options);

    return response.data as T;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  }
};
export const requestApi = async <ResponseType = any>({
  method,
  url,
  data,
  params,
  query,
}: ApiParams & AxiosRequestConfig): Promise<ResponseType> => {
  let finalUrl = url;

  // Path parameter 처리
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      finalUrl = finalUrl?.replace(`:${key}`, String(value));
    });
  }

  // Query string 처리
  if (query) {
    const queryParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
      queryParams.append(key, String(value));
    });
    finalUrl = `${finalUrl}?${queryParams.toString()}`;
  }

  try {
    const response: AxiosResponse<ResponseType> = await api({
      method,
      url: finalUrl,
      data,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  }
};

// API 호출 함수 생성기
export const createApiFunction = <RequestType = any, ResponseType = any, QueryType = any>({
  method,
  url,
}: CreateApiFunctionOptions) => {
  return async ({ data, query, params }: ApiParams<RequestType, QueryType> = {}): Promise<ResponseType> => {
    return requestApi<ResponseType>({
      method,
      url,
      data,
      params,
      query,
    });
  };
};
